import React, { useState } from "react";

function Counter() {
	let [counter, setCounter] = useState(20);

	return (
		<>
			<div>Hallo counter{counter}</div>
			<div onClick={() => setCounter((counter += 1))}>+</div>
			<div onClick={() => setCounter((counter -= 1))}>-</div>
		</>
	);
}
export default Counter;
